import { Router, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoginFailedComponent } from './login-failed.component';
import { AppLayoutComponent } from './core/components/app-layout/app-layout.component';
import { LoggedOutComponent } from './core/components/logged-out/logged-out.component';

export class EllyRoutes {
  //#region Other Elements
  /** `/` */
  public static readonly SEPERATOR: string = '/';

  /** `:promptterm` */
  public static readonly PROMPT_TERM: string = ':promptterm';
  //#endregion

  //#region Route Elements
  /** `chat` */
  public static readonly CHAT_ROUTE: string = 'chat';

  /** **CHAT_ROUTE** + **SEPERATOR** */
  public static readonly CHAT_ROUTE_AND_SEPERATOR: string =
    this.CHAT_ROUTE + this.SEPERATOR;

  /** `all` */
  public static readonly ALL: string = 'all';

  /** `general` */
  public static readonly GENERAL: string = 'general';

  /** `history` */
  public static readonly HISTORY: string = 'history';

  /** `initiate` */
  public static readonly INITIATE: string = 'initiate';

  /** `recruiting` */
  public static readonly RECRUITING: string = 'recruiting';

  /** `sales` */
  public static readonly SALES: string = 'sales';

  /** `demo` */
  public static readonly DEMO: string = 'demo';

  /** `customerservice` */
  public static readonly CSR: string = 'customerservice';

  /** `customerservice` */
  public static readonly PRESALES: string = 'presales';

  /** `banking` */
  public static readonly BANKING: string = 'banking';

  /** `dashboard` */
  public static readonly DASHBOARD: string = 'dashboard';
  //#endregion

  //#region Chat Routes
  /** **SEPERATOR** + **CHAT_ROUTE_AND_SEPERATOR** + **ALL** */
  public static readonly CHAT_ALL: string =
    this.SEPERATOR + this.CHAT_ROUTE_AND_SEPERATOR + this.ALL;

  /** **SEPERATOR** + **CHAT_ROUTE_AND_SEPERATOR** + **GENERAL** */
  public static readonly CHAT_GENERAL: string =
    this.SEPERATOR + this.CHAT_ROUTE_AND_SEPERATOR + this.GENERAL;

  /** **SEPERATOR** + **CHAT_ROUTE_AND_SEPERATOR** + **HISTORY** */
  public static readonly CHAT_HISTORY: string =
    this.SEPERATOR + this.CHAT_ROUTE_AND_SEPERATOR + this.HISTORY;

  /** **SEPERATOR** + **CHAT_ROUTE_AND_SEPERATOR**  + **INITIATE** */
  public static readonly CHAT_INITIATE: string =
    this.SEPERATOR + this.CHAT_ROUTE_AND_SEPERATOR + this.INITIATE;

  /** **SEPERATOR** + **CHAT_ROUTE_AND_SEPERATOR**  + **RECRUITING** */
  public static readonly CHAT_RECRUITING: string =
    this.SEPERATOR + this.CHAT_ROUTE_AND_SEPERATOR + this.RECRUITING;

  /** **SEPERATOR** + **CHAT_ROUTE_AND_SEPERATOR**  + **SALES** */
  public static readonly CHAT_SALES: string =
    this.SEPERATOR + this.CHAT_ROUTE_AND_SEPERATOR + this.SALES;

  /** **SEPERATOR** + **CHAT_ROUTE_AND_SEPERATOR**  + **DEMO** */
  public static readonly CHAT_DEMO: string =
    this.SEPERATOR + this.CHAT_ROUTE_AND_SEPERATOR + this.DEMO;

  /** **SEPERATOR** + **CHAT_ROUTE_AND_SEPERATOR**  + **CSR** */
  public static readonly CHAT_CSR: string =
    this.SEPERATOR + this.CHAT_ROUTE_AND_SEPERATOR + this.CSR;

  /** **SEPERATOR** + **CHAT_ROUTE_AND_SEPERATOR**  + **PRESALES** */
  public static readonly CHAT_PRESALES: string =
    this.SEPERATOR + this.CHAT_ROUTE_AND_SEPERATOR + this.PRESALES;

  /** **SEPERATOR** + **CHAT_ROUTE_AND_SEPERATOR**  + **BANKING** */
  public static readonly CHAT_BANKING: string =
    this.SEPERATOR + this.CHAT_ROUTE_AND_SEPERATOR + this.BANKING;

  /** **SEPERATOR** + **CHAT_ROUTE_AND_SEPERATOR**  + **DASHBOARD** */
  public static readonly CHAT_DASHBOARD: string =
    this.SEPERATOR + this.CHAT_ROUTE_AND_SEPERATOR + this.DASHBOARD;
  //#endregion

  //#region Prompt Routes
  /** **ALL** + **SEPERATOR** + **PROMPT_TERM** */
  public static readonly ALL_PROMPTS: string =
    this.ALL + this.SEPERATOR + this.PROMPT_TERM;

  /** **GENERAL** + **SEPERATOR** + **PROMPT_TERM** */
  public static readonly GENERAL_PROMPTS: string =
    this.GENERAL + this.SEPERATOR + this.PROMPT_TERM;

  /** **SALES** + **SEPERATOR** + **PROMPT_TERM** */
  public static readonly SALES_PROMPTS: string =
    EllyRoutes.SALES + EllyRoutes.SEPERATOR + EllyRoutes.PROMPT_TERM;

  /** **RECRUITING** + **SEPERATOR** + **PROMPT_TERM** */
  public static readonly RECRUITING_PROMPTS: string =
    this.RECRUITING + this.SEPERATOR + this.PROMPT_TERM;

  /** **DEMO** + **SEPERATOR** + **PROMPT_TERM** */
  public static readonly DEMO_PROMPTS: string =
    this.DEMO + this.SEPERATOR + this.PROMPT_TERM;

  /** **CSR** + **SEPERATOR** + **PROMPT_TERM** */
  public static readonly CSR_PROMPTS: string =
    this.CSR + this.SEPERATOR + this.PROMPT_TERM;

  /** **PRESALES** + **SEPERATOR** + **PROMPT_TERM** */
  public static readonly PRESALES_PROMPTS: string =
    this.PRESALES + this.SEPERATOR + this.PROMPT_TERM;

  /** **BANKING** + **SEPERATOR** + **PROMPT_TERM** */
  public static readonly BANKING_PROMPTS: string =
    this.BANKING + this.SEPERATOR + this.PROMPT_TERM;

  //#endregion

  //#region Admin Routes
  /** `admin` */
  public static readonly ADMIN_ROUTE: string = 'admin';

  /** `documents` */
  public static readonly DOCUMENTS: string = 'documents';
  public static readonly APPSETTINGS: string = 'appSettings';

  /** **SEPERATOR** + **ADMIN_ROUTE** + **SEPERATOR** + **DOCUMENTS** */
  public static readonly ADMIN_DOCUMENTS: string =
    this.SEPERATOR + this.ADMIN_ROUTE + this.SEPERATOR + this.DOCUMENTS;
  public static readonly ADMIN_APPSETTINGS: string =
    this.SEPERATOR + this.ADMIN_ROUTE + this.SEPERATOR + this.APPSETTINGS;

  //#endregion



  //#region Py Routes
  /** `admin` */
  public static readonly PY_ROUTE: string = 'py';

  /** `NLP2MONGO` */
  public static readonly NLP2MONGO: string = 'nlp2mongo';

  /** **SEPERATOR** + **PY_ROUTE** + **SEPERATOR** + **NLP2MONGO** */
  public static readonly PY_NLP2MONGO: string =
    this.SEPERATOR + this.PY_ROUTE + this.SEPERATOR + this.NLP2MONGO;

  //#endregion

  public static routeIsAnAppletsPage(route: string): boolean {
    return (
      route == this.CHAT_ALL ||
      route == this.CHAT_GENERAL ||
      route == this.CHAT_SALES ||
      route == this.CHAT_RECRUITING ||
      route == this.CHAT_DEMO ||
      route == this.CHAT_BANKING ||
      route == this.CHAT_CSR ||
      route == this.CHAT_PRESALES
    );
  }
}

export const appRoutes: Routes = [
  {
    path: EllyRoutes.CHAT_ROUTE,
    component: AppLayoutComponent,

    loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent,
  },
  {
    path: 'signed-out',
    component: LoggedOutComponent,
  },
  {
    path: EllyRoutes.ADMIN_ROUTE,
    component: AppLayoutComponent,
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: EllyRoutes.PY_ROUTE,
    component: AppLayoutComponent,
    loadChildren: () =>
      import('./py/py.module').then((m) => m.PyModule),
  } ,
  {
    path: '**',
    redirectTo: EllyRoutes.CHAT_DASHBOARD,
  },
];
