import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EllyRoutes } from 'src/app/app.routing';
import { currentAppSettings } from 'src/app/appsettings.service';
import {
  MenuItem,
  MenuItemType,
} from 'src/app/shared/interfaces/menu-item.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  protected menuItems: BehaviorSubject<MenuItem[] | null> = new BehaviorSubject<
    MenuItem[] | null
  >(null);
  public menuItems$: Observable<MenuItem[] | null> =
    this.menuItems.asObservable();

  private headers = new HttpHeaders({
    //'Content-Type': 'application/json',
    //'Authorization': `Bearer ${this.apiKey}`
    'Access-Control-Allow-Methods': 'GET,POST',
    'Access-Control-Allow-Origin': '*', //'https://api.openai.com'
    'Access-Control-Allow-Headers': '*', //'Origin, X-Requested-With, Content-Type, Accept'
  });

  constructor(private httpClient: HttpClient) {}

  public getMenuItems(user: User | null) {
    this.menuItems.next(null);

    let menuItems: MenuItem[] = [
      {
        id: 1,
        name: 'general',
        navigateTo: EllyRoutes.CHAT_GENERAL,
        iconName: 'robot_2',
        menuType: MenuItemType.CUSTOM,
        tooltip: !user?.isExternal ? 'Chat with Elly' : 'Chat with AI',
      },
      {
        id: 6,
        name: 'history',
        navigateTo: EllyRoutes.CHAT_HISTORY,
        iconName: 'history',
        menuType: MenuItemType.CUSTOM,
        tooltip: 'Chat History',
      },
    ];

    if (!user?.isExternal && user?.isAdmin) {
      menuItems.push({
        id: -1,
        name: 'settings',
        navigateTo: EllyRoutes.ADMIN_APPSETTINGS,
        iconName: 'settings',
        menuType: MenuItemType.CUSTOM,
        tooltip: 'App Settings',
      });

      menuItems.push({
        id: 0,
        name: 'fileupload',
        navigateTo: EllyRoutes.ADMIN_DOCUMENTS,
        iconName: 'cloud_upload',
        menuType: MenuItemType.CUSTOM,
        tooltip: 'Upload Documents',
      });
    }

    if (!user?.isExternal) {
      menuItems.push(
        {
          id: 2,
          name: 'recruiting',
          navigateTo: EllyRoutes.CHAT_RECRUITING,
          iconName: 'group_add',
          menuType: MenuItemType.DEFAULT,
          tooltip: 'Recruiting',
        },
        {
          id: 3,
          name: 'sales',
          navigateTo: EllyRoutes.CHAT_SALES,
          iconName: 'sell',
          menuType: MenuItemType.DEFAULT,
          tooltip: 'Sales',
        }
      );
    }

    if (!environment.production) {
      menuItems.push({
        id: 4,
        name: 'demo',
        navigateTo: 'chat/demo',
        iconName: 'military_tech',
        menuType: MenuItemType.CUSTOM,
        tooltip: 'Demo',
      });

      menuItems.push({
        id: 5,
        name: 'banking',
        navigateTo: EllyRoutes.CHAT_BANKING,
        iconName: 'savings',
        menuType: MenuItemType.CUSTOM,
        tooltip: 'Banking',
      });

      // menuItems.push({
      //   id: 6,
      //   name: 'Customer Service',
      //   navigateTo: 'chat/customerservice',
      //   iconName: 'support_agent',
      //   menuType: MenuItemType.CUSTOM,
      //   tooltip: 'Customer Service',
      // });
    }
    menuItems.push({
      id: 7,
      name: 'PreSales',
      navigateTo: 'chat/presales',
      iconName: 'support_agent',
      menuType: MenuItemType.CUSTOM,
      tooltip: 'PreSales',
    });

    menuItems.push({
      id: 8,
      name: 'nlp2Mongo',
      navigateTo: EllyRoutes.PY_ROUTE,
      iconName: 'support_agent',
      menuType: MenuItemType.CUSTOM,
      tooltip: 'Nlp2Mongo',
    });
    this.menuItems.next(menuItems.sort((a, b) => a.id - b.id));
  }
}
